import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Redirect } from '@reach/router';

export interface FourOhFourProps {}

const FourOhFour: React.FunctionComponent<FourOhFourProps> = (): JSX.Element => {
  return <Redirect to="/app/user" noThrow />;
};

export default FourOhFour;
